<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <h4 class="create-title">{{ $lang.app.editing }}</h4>
    <div class="admin-form-wrapper">
      <div v-if="deadline" class="admin-form">
        <Form @submit="submit">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.title }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="name" type="text" v-model="deadline.name"/>
            </div>
            <ErrorMessage name="name" class="recovery-label-error" />
          </div>
          <div class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.start_year }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="start_year" v-slot="{ field }">
                  <Picker v-model="deadline.startYear" yearPicker
                          v-bind="field"
                          locale="ru"
                          cancelText=""
                          selectText="Ок"/>
                </Field>
              </div>
              <ErrorMessage name="start_year" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.end_year }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="start_year" v-slot="{ field }">
                  <Picker v-model="deadline.endYear" yearPicker
                          v-bind="field"
                          locale="ru"
                          cancelText=""
                          selectText="Ок"/>
                </Field>
              </div>
              <ErrorMessage name="end_year" class="recovery-label-error" />
            </div>
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.registration_by_residence_start }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="registrationByResidenceStart" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="deadline.registrationByResidenceStart"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    selectText="Ок">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="registrationByResidenceStart" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.registration_by_residence_end }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="registrationByResidenceEnd" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="deadline.registrationByResidenceEnd"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    selectText="Ок">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="registrationByResidenceEnd" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.registration_outside_residence_start }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="registrationOutsideResidenceStart" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="deadline.registrationOutsideResidenceStart"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    selectText="Ок">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="registrationOutsideResidenceStart" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.registration_outside_residence_end }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="registrationOutsideResidenceEnd" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="deadline.registrationOutsideResidenceEnd"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    selectText="Ок">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="registrationOutsideResidenceEnd" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.document_submission_start }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="documentSubmissionStart" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="deadline.documentSubmissionStart"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    selectText="Ок">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="documentSubmissionStart" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.document_submission_end }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="documentSubmissionEnd" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="deadline.documentSubmissionEnd"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    selectText="Ок">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="documentSubmissionEnd" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.first_grade_enrollment_start }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="firstGradeEnrolmentStart" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="deadline.firstGradeEnrolmentStart"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    selectText="Ок">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="firstGradeEnrolmentStart" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.first_grade_enrollment_end }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="firstGradeEnrolmentEnd" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="deadline.firstGradeEnrolmentEnd"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    selectText="Ок">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="firstGradeEnrolmentEnd" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.registration_deadline }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="registrationDeadline" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="deadline.registrationDeadline"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    selectText="Ок">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="registrationDeadline" class="recovery-label-error" />
          </div>


          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.gorono_registration_start }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="goronoRegistrationStart" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="deadline.goronoRegistrationStart"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    selectText="Ок">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="goronoRegistrationStart" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.gorono_registration_end }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="goronoRegistrationEnd" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="deadline.goronoRegistrationEnd"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    selectText="Ок">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="goronoRegistrationEnd" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <div class="checkbox-container">
              <Field type="checkbox" name="archived"
                     id="archived"
                     v-model="deadline.archived"
                     :value="true"/>
              <label for="archived">
                <span>{{ $lang.app.archived }}</span>
              </label>
            </div>
            <ErrorMessage name="archived" class="recovery-label-error" />
          </div>
          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.update }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
import Picker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    Datepicker,
    Picker
  },
  data() {
    return {
      deadline: null
    }
  },
  methods: {
    format(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
      const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
      const hours = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
      const minutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();

      return `${day}/${month}/${year}, ${hours}:${minutes}`;
    },
    submit(values, actions) {

      this.deadline = {
        archived: !!this.deadline.archived,
        documentSubmissionEnd: this.$moment(this.deadline.documentSubmissionEnd).format('YYYY-MM-DD HH:mm:ss'),
        documentSubmissionStart: this.$moment(this.deadline.documentSubmissionStart).format('YYYY-MM-DD HH:mm:ss'),
        firstGradeEnrolmentEnd: this.$moment(this.deadline.firstGradeEnrolmentEnd).format('YYYY-MM-DD HH:mm:ss'),
        firstGradeEnrolmentStart: this.$moment(this.deadline.firstGradeEnrolmentStart).format('YYYY-MM-DD HH:mm:ss'),
        name: this.deadline.name,
        startYear: this.deadline.startYear,
        endYear: this.deadline.endYear,
        registrationByResidenceEnd: this.$moment(this.deadline.registrationByResidenceEnd).format('YYYY-MM-DD HH:mm:ss'),
        registrationByResidenceStart: this.$moment(this.deadline.registrationByResidenceStart).format('YYYY-MM-DD HH:mm:ss'),
        registrationDeadline: this.$moment(this.deadline.registrationDeadline).format('YYYY-MM-DD HH:mm:ss'),
        registrationOutsideResidenceEnd: this.$moment(this.deadline.registrationOutsideResidenceEnd).format('YYYY-MM-DD HH:mm:ss'),
        registrationOutsideResidenceStart: this.$moment(this.deadline.registrationOutsideResidenceStart).format('YYYY-MM-DD HH:mm:ss'),
        goronoRegistrationStart: this.$moment(this.deadline.goronoRegistrationStart).format('YYYY-MM-DD HH:mm:ss'),
        goronoRegistrationEnd: this.$moment(this.deadline.goronoRegistrationEnd).format('YYYY-MM-DD HH:mm:ss'),
      }

      this.$axios.post(`/academic-year/edit/${this.$route.params.id}`, this.deadline).then(() => {
        this.$snotify.success(this.$lang.app.resource_updated_successfully);
        this.$router.push('/admin/deadlines')
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
      })
    },
    fetchResource() {
      this.$axios.get(`/academic-year/${this.$route.params.id}`).then(({data}) => {
        this.deadline = {
          archived: data.archived,
          documentSubmissionEnd: new Date(data.documentSubmissionEnd),
          documentSubmissionStart: new Date(data.documentSubmissionStart),
          firstGradeEnrolmentEnd: new Date(data.firstGradeEnrolmentEnd),
          firstGradeEnrolmentStart: new Date(data.firstGradeEnrolmentStart),
          name: data.name,
          startYear: data.startYear,
          endYear: data.endYear,
          registrationByResidenceEnd: new Date(data.registrationByResidenceEnd),
          registrationByResidenceStart: new Date(data.registrationByResidenceStart),
          registrationDeadline: new Date(data.registrationDeadline),
          registrationOutsideResidenceEnd: new Date(data.registrationOutsideResidenceEnd),
          registrationOutsideResidenceStart: new Date(data.registrationOutsideResidenceStart),
          goronoRegistrationStart: new Date(data.goronoRegistrationStart),
          goronoRegistrationEnd: new Date(data.goronoRegistrationEnd),
        }
      })
    }
  },
  mounted() {
    this.fetchResource()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
@import '../../../assets/styles/date-time-picker.css';
</style>